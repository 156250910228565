import { Theme } from "typed-component-gallery";

export const themeConfig: Theme = {
  colors: ["hsla(344, 58%, 56%, 1)", "hsla(22, 64%, 23%, 1)"],
  font: "roboto",
  fontSize: "1.25px",
  white: "hsla(344, 58%, 56%, 1)",
  borderRadius: "3px",
  border: "1px solid grey",
  mode: "light",
  input: {
    border: true,
    style: { border: "1px solid black" },
    rounded: true,
  },
};
