import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import amazonEcho from "../assets/amazonEcho.jpg";
import googleHome from "../assets/googleHome.webp";
import homePod from "../assets/homePod.jpg";
import ReusableBlogPost from "../products/blogPost";
import { DBList } from "../widgets/dbList";
const Product: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  path: string;
}> = ({ data, update, path }) => {
  const { logic } = useEloise();

  return (
    <Row>
      <ReusableBlogPost
        {...data}
        preview
      />
    </Row>
  );
};
export const SmartAss: React.FC = () => {
  const filterFunction = (data: any) => {
    return data.data.active && data.data.cat === "smartAssistants";
  };
  return (
    <Container>
      <Row className="my-4">
        <Col
          lg={4}
          className="text-center">
          <a
            href="https://amzn.to/3PB8PjH"
            target="_blank">
            <img
              style={{ maxWidth: "25vw", textAlign: "center" }}
              src={amazonEcho}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center">
          <a
            href="https://amzn.to/455Vjtf"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={googleHome}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center">
          <a
            href="https://amzn.to/455Vjtf"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={homePod}
            />
          </a>
        </Col>
      </Row>
      <Row className="my-3">
        <Col
          lg={11}
          style={{ borderRadius: "5px", border: "1px solid black" }}
          className="mx-auto ">
          <div className=" p-3">
            <h2>Smart Home Assistants: Google Assistant, Siri, and Alexa</h2>
            <p>
              Unlock the ultimate convenience and intelligence in your home with
              smart home assistants. These intuitive platforms allow you to
              control lights, set reminders, get updates, and so much more—all
              through the sound of your voice. Here's a brief look at the top
              three industry leaders: Google Assistant, Siri, and Alexa.
            </p>

            <hr />

            <section>
              <h3>Google Assistant: Your Personal Google</h3>
              <h4>Intelligence, Meet Efficiency</h4>
              <p>
                Google Assistant is renowned for its exceptional voice
                recognition and intuitive understanding of natural language.
                Utilizing the vast database of Google, it excels in answering
                complex queries and executing multifaceted tasks. If you're
                deeply integrated into the Google ecosystem—think Android
                phones, Google Home, or Chromebooks—this assistant is the
                seamless extension of your digital life.
              </p>
              <h5>🌟 Key Features</h5>
              <ul>
                <li>Voice-activated Google Search</li>
                <li>Comprehensive home automation</li>
                <li>Rich third-party app integrations</li>
                <li>Multi-language support</li>
              </ul>
            </section>

            <hr />

            <section>
              <h3>Siri: The iOS Enigma</h3>
              <h4>Sleek, Simple, Sophisticated</h4>
              <p>
                Siri is the go-to for Apple enthusiasts. Integrated into every
                iOS device, Siri offers a user experience that is as sleek as
                the hardware it runs on. With a focus on user privacy and a
                streamlined interface, Siri makes everyday tasks feel
                effortless. If you’re a part of the Apple universe, Siri is the
                glue that brings it all together.
              </p>
              <h5>🌟 Key Features</h5>
              <ul>
                <li>Exceptional data privacy</li>
                <li>User-friendly interface</li>
                <li>Seamless integration with iOS</li>
                <li>Shortcuts for customized tasks</li>
              </ul>
            </section>

            <hr />

            <section>
              <h3>Alexa: Your Home, Smarter</h3>
              <h4>Convenience at Every Corner</h4>
              <p>
                Alexa, the brain behind Amazon's Echo devices, offers a broad
                range of skills and compatibility with various smart home
                devices. Whether you're new to smart homes or a seasoned expert,
                Alexa's user-friendly setup ensures that you're never more than
                a command away from comfort. Plus, with continual updates, Alexa
                keeps getting smarter.
              </p>
              <h5>🌟 Key Features</h5>
              <ul>
                <li>Extensive smart home control</li>
                <li>Wide range of skills and third-party integrations</li>
                <li>Easy setup and user-friendly interface</li>
                <li>
                  Compatible with Amazon services like Prime, Music, and more
                </li>
              </ul>
            </section>

            <p>
              No matter which assistant you choose, you're taking a significant
              step toward a smarter, more efficient living space. Each platform
              has its own set of unique benefits that cater to different
              lifestyles and preferences. Discover your match and elevate your
              smart home experience to unprecedented heights.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DBList
            noAuth
            filter={filterFunction}
            path="products"
            Component={Product}
          />
        </Col>
      </Row>
    </Container>
  );
};
