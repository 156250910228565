import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface BlogProps {
  preview: boolean;
  title: string;
  previewText: string;
  bodyText: string;
  link: string;
  image: string;
  id: string;
}

const ThumbnailWrapper = styled.div`
  width: 200px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ReusableBlogThumbnail: React.FC<BlogProps> = ({
  preview,
  title,
  previewText,
  bodyText,
  link,
  image,
  id,
}) => {
  return (
    <ThumbnailWrapper>
      <h4>
        <Link to={`/product/${id}`}>{title}</Link>
      </h4>
      <p>{previewText}</p>
    </ThumbnailWrapper>
  );
};

export default ReusableBlogThumbnail;
