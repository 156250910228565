import { mdiAccount, mdiAccountHardHat, mdiAccountTie } from "@mdi/js";
import { SiteConfig } from "typed-component-gallery";
import { pages } from "./pages";
// api: "http://127.0.0.1:5001/eloiselife-c5cf6/us-central1/text",

import {
  default as inverseLogo,
  default as logo,
} from "../components/assets/logo.png";

export const site: SiteConfig = {
  api: "https://us-central1-eloiselife-c5cf6.cloudfunctions.net/text",
  name: "Futuristic Living",
  id: "futureliving",
  logo: logo,
  inverseLogo: inverseLogo,
  sideWidget: [],
  defaultMode: "Buyer/Seller",
  pages,
  noAuth: true,
  headerTrans: false,
  eloiseConfig: {
    endPoint: "https://us-central1-eloiselife-c5cf6.cloudfunctions.net/chat",
    chatLog: "vibez/chat",
    initMessage:
      "Hey there! I'm Eloise your virtual assistant. Let me know if you need anything  ",
  },
  peopleConfig: [
    { title: "Agent", icon: mdiAccountTie },
    { title: "Contractor", icon: mdiAccountHardHat },
    { title: "Buyer/Seller", icon: mdiAccount },
  ],
};
