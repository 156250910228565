import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import entry1 from "../assets/entry1.webp";
import entry2 from "../assets/entry2.jpg";
import entry3 from "../assets/entry3.webp";

import ReusableBlogPost from "../products/blogPost";
import { DBList } from "../widgets/dbList";
const Product: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  path: string;
}> = ({ data, update, path }) => {
  const { logic } = useEloise();

  return (
    <Row>
      <ReusableBlogPost
        {...data}
        preview
      />
    </Row>
  );
};
export const Entry: React.FC = () => {
  const filterFunction = (data: any) => {
    return data.data.active && data.data.cat === "locks";
  };
  return (
    <Container>
      <Row className="my-4">
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/3PhzG33"
            target="_blank">
            <img
              style={{ maxWidth: "25vw", textAlign: "center" }}
              src={entry1}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/3PCfElc"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={entry2}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center">
          <a
            href="https://amzn.to/3RkJVGu"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={entry3}
            />
          </a>
        </Col>
      </Row>
      <Row className="my-3">
        <Col
          lg={11}
          style={{ borderRadius: "5px", border: "1px solid black" }}
          className="mx-auto ">
          <div className=" p-3">
            {" "}
            <section>
              <h3>Unlock the Potential of Smart Entry Equipment</h3>
              <p>
                🔒 Discover the convenience and security features that smart
                entry systems bring to your doorstep.
              </p>

              <h4>Keyless Entry</h4>
              <p>
                With smart locks, you can forget about physical keys. Use your
                smartphone or even voice commands to lock and unlock your doors.
              </p>

              <h4>Visitor Verification</h4>
              <p>
                Smart doorbells come with cameras that let you verify who is at
                your door without having to open it, offering both safety and
                convenience.
              </p>

              <h4>Remote Access</h4>
              <p>
                Control your entry systems from anywhere. Lock your doors or
                interact with visitors remotely right from your smartphone.
              </p>

              <h4>Activity Logs</h4>
              <p>
                Keep track of who comes and goes with detailed logs and
                notifications. Know when your kids arrive home or if a package
                has been delivered.
              </p>

              <h4>Integration with Home Security</h4>
              <p>
                Smart entry systems often integrate seamlessly with other smart
                home security devices, enhancing the overall safety of your
                home.
              </p>
            </section>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DBList
            noAuth
            filter={filterFunction}
            path="products"
            Component={Product}
          />
        </Col>
      </Row>
    </Container>
  );
};
