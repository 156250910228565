export const fb = {
  config: {
    apiKey: "AIzaSyAip270spGAtD0kVCnd2fxndu61T4iIznI",
    authDomain: "eloiselife-c5cf6.firebaseapp.com",
    databaseURL: "https://eloiselife-c5cf6-default-rtdb.firebaseio.com",
    projectId: "eloiselife-c5cf6",
    storageBucket: "eloiselife-c5cf6.appspot.com",
    messagingSenderId: "755349013197",
    appId: "1:755349013197:web:6301d92b82249a7b3f1f84",
    measurementId: "G-FJSDX1NYEC",
  },
  storageDir: "test",
};
