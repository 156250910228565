import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";

import utils1 from "../assets/utils1.webp";
import utils2 from "../assets/utils2.jpg";
import utils3 from "../assets/utils3.webp";

import ReusableBlogPost from "../products/blogPost";
import { DBList } from "../widgets/dbList";
const Product: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  path: string;
}> = ({ data, update, path }) => {
  const { logic } = useEloise();

  return (
    <Row>
      <ReusableBlogPost
        {...data}
        preview
      />
    </Row>
  );
};
export const Utils: React.FC = () => {
  const filterFunction = (data: any) => {
    return data.data.active && data.data.cat === "utils";
  };
  return (
    <Container>
      <Row className="my-4">
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/44SvP22"
            target="_blank">
            <img
              style={{ maxWidth: "25vw", textAlign: "center" }}
              src={utils1}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/3EA5jjg"
            target="_blank">
            <img
              style={{
                maxWidth: "29vw",
                maxHeight: "400px",
                textAlign: "center",
              }}
              src={utils2}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center">
          <a
            href="https://amzn.to/3Ll2iaq"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={utils3}
            />
          </a>
        </Col>
      </Row>
      <Row className="my-3">
        <Col
          lg={11}
          style={{ borderRadius: "5px", border: "1px solid black" }}
          className="mx-auto ">
          <div className=" p-3">
            {" "}
            <section>
              <h3>The Wonders of Miscellaneous Smart Home Gadgets</h3>
              <p>
                🛠 Take a deep dive into the innovative smart gadgets that make
                your life simpler and healthier.
              </p>

              <h4>Automated Cleaning</h4>
              <p>
                Robotic vacuum cleaners like Roombas offer scheduled and
                automated cleaning, allowing you to focus on other activities.
              </p>

              <h4>Improved Air Quality</h4>
              <p>
                Smart air purifiers provide real-time air quality monitoring and
                filtration, purifying the air you breathe at home.
              </p>

              <h4>Water Management</h4>
              <p>
                Smart plumbing gadgets can monitor your water usage, detect
                leaks, and even purify water for you.
              </p>

              <h4>Remote Monitoring</h4>
              <p>
                Keep track of your home's vital functions remotely. Control your
                Roomba or monitor your air quality from anywhere with your
                smartphone.
              </p>

              <h4>Interconnectivity</h4>
              <p>
                These gadgets often integrate with other smart home systems,
                creating a seamless, efficient smart home ecosystem.
              </p>
            </section>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DBList
            noAuth
            filter={filterFunction}
            path="products"
            Component={Product}
          />
        </Col>
      </Row>
    </Container>
  );
};
