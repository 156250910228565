import React, { useState } from "react";
import { useParams } from "react-router";
import { Loading, useEloise } from "typed-component-gallery";
import amazon1 from "../assets/amazon1.jpg";
import ReusableBlogPost from "./blogPost";
interface BlogProps1 {
  preview: boolean;
  title: string;
  previewText: string;
  bodyText: string[];
  link: string;
  image: string;
  active: boolean;
  section: string;
}

export const ProductPage: React.FC = () => {
  const { id } = useParams();
  const { logic } = useEloise();
  const [data, setData] = useState<any>();

  logic.hooks.useAsyncEffect(async () => {
    let test = await logic.fb.docs.getDoc("products/" + id);
    if (test) {
      console.log(test);
      setData(test);
    }
  }, []);

  return (
    <>
      {" "}
      {data ? (
        <ReusableBlogPost
          noCollapse
          title={data.data.title ?? "Title"}
          previewText={data.data.previewText ?? "Preview Text"}
          link={data.data.link ?? "Link"}
          bodyText={data.data.bodyText ?? ["Body Text"]}
          image={data.data.image ?? amazon1}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
