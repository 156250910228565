import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import styled from "styled-components";

interface BlogProps {
  preview?: boolean;
  title: string;
  previewText: string;
  bodyText: string[];
  link: string;
  image: string;
  noCollapse?: boolean;
}

const StyledCard = styled(Card)`
  margin: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
`;

const ReusableBlogPost: React.FC<BlogProps> = ({
  preview,
  title,
  previewText,
  bodyText,
  link,
  image,
  noCollapse,
}) => {
  const [expand, setExpand] = useState(!preview);

  return (
    <StyledCard>
      <Card.Img
        style={{ maxHeight: "400px", maxWidth: "400px" }}
        variant="top"
        src={image}
      />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        {!expand ? (
          <Card.Text>{previewText}</Card.Text>
        ) : (
          <>
            {bodyText &&
              bodyText.map((paragraph, index) => (
                <Card.Text key={index}>
                  {paragraph.replace(/^"|"$/g, "")}
                </Card.Text>
              ))}
          </>
        )}
        {!noCollapse && (
          <StyledButton onClick={() => setExpand(!expand)}>
            Read {!expand ? "More" : "Less"}
          </StyledButton>
        )}
        <StyledButton
          variant="primary"
          href={link}
          target="_blank"
          rel="noopener noreferrer">
          View on Amazon
        </StyledButton>
      </Card.Body>
    </StyledCard>
  );
};

export default ReusableBlogPost;
