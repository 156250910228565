import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import ReusableBlogThumbnail from "../products/BlogThumbnail";
import { DBList } from "../widgets/dbList";

const Product: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  path: string;
  id?: string;
}> = ({ data, update, path, id }) => {
  const { logic } = useEloise();

  return (
    <Row>
      <ReusableBlogThumbnail
        {...data}
        id={id}
      />
    </Row>
  );
};
export const Home: React.FC = () => {
  const filterFunction = (data: any) => {
    return data.data.active;
  };

  return (
    <Container>
      <Row>
        <Col lg={10}>
          <Row>
            <iframe
              width="560"
              height="500"
              src="https://www.youtube.com/embed/yDNWVRmRHlY?si=ZgWoAZbZr3gmipCi"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </Row>
          <Row className="my-4">
            <iframe
              width="560"
              height="500"
              src="https://www.youtube.com/embed/cb7-Nzy93m0?si=9a6p_3T6VF8HU-H5"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </Row>
          <Row className="my-4">
            <iframe
              width="560"
              height="500"
              src="https://www.youtube.com/embed/Biv5ZfNEGAE?si=7wJ7axq6wt0ArwzM"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </Row>
        </Col>
        <Col lg={2}>
          <DBList
            noAuth
            filter={filterFunction}
            path="products"
            Component={Product}
          />
        </Col>
      </Row>
    </Container>
  );
};
