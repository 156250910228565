import { EloisePage } from "typed-component-gallery";
import { Admin } from "../components/pages/admin";
import { Daily } from "../components/pages/daily";
import { Home } from "../components/pages/home";
import { Cameras } from "../components/products/cameras";
import { Entry } from "../components/products/entry";
import { Lighting } from "../components/products/lighting";
import { ProductPage } from "../components/products/productPage";
import { SmartAss } from "../components/products/smartAssistants";
import { Utils } from "../components/products/utils";

export const sections: EloisePage[] = [
  {
    name: "Daily Deals",
    url: "daily",
    component: <Daily />,
    noAuth: true,
  },
  {
    name: "Favorites",
    url: "favs",
    component: <Daily />,
    noAuth: true,
  },
  {
    name: "New",
    url: "new",
    component: <Daily />,
    noAuth: true,
  },
];

export const cats = [
  {
    name: "Smart Assistants",
    url: "smartAssistants",
    component: <SmartAss />,
    noAuth: true,
  },
  {
    name: "Lighting",
    url: "lighting",

    component: <Lighting />,
    noAuth: true,
  },
  {
    name: "Cameras",
    url: "cams",

    component: <Cameras />,
    noAuth: true,
  },
  {
    name: "Locks and Entry",
    url: "locks",

    component: <Entry />,
    noAuth: true,
  },
  {
    name: "Utilities",
    url: "utils",

    component: <Utils />,
    noAuth: true,
  },
];

export const pages: EloisePage[] = [
  {
    name: "/",
    component: <Home />,
    noAuth: true,
  },

  {
    name: "admin",
    component: <Admin />,
    hidden: true,
  },
  {
    name: "product",
    hidden: true,
    url: "product/:id",
    component: <ProductPage />,
  },
  {
    name: "Categories",
    pages: [...cats],
  },

  ...sections,
];
