import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import cam1 from "../assets/smartCam1.jpg";
import cam2 from "../assets/smartCam2.webp";
import cam3 from "../assets/smartCam3.jpg";
import ReusableBlogPost from "../products/blogPost";
import { DBList } from "../widgets/dbList";
const Product: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  path: string;
}> = ({ data, update, path }) => {
  const { logic } = useEloise();

  return (
    <Row>
      <ReusableBlogPost
        {...data}
        preview
      />
    </Row>
  );
};
export const Cameras: React.FC = () => {
  const filterFunction = (data: any) => {
    return data.data.active && data.data.cat === "cams";
  };
  return (
    <Container>
      <Row className="my-4">
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/3PzWJHQ"
            target="_blank">
            <img
              style={{ maxWidth: "25vw", textAlign: "center" }}
              src={cam1}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/44PzMEM"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={cam2}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center">
          <a
            href="https://amzn.to/3sMSC1X"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={cam3}
            />
          </a>
        </Col>
      </Row>
      <Row className="my-3">
        <Col
          lg={11}
          style={{ borderRadius: "5px", border: "1px solid black" }}
          className="mx-auto ">
          <div className=" p-3">
            {" "}
            <section>
              <h3>Explore the Capabilities of Smart Cameras</h3>
              <p>
                📷 Get to know the variety of functionalities that smart cameras
                bring to your home security and convenience.
              </p>

              <h4>Surveillance</h4>
              <p>
                Keep a watchful eye on your property 24/7. Whether you're at
                home or away, smart cameras provide real-time video feeds
                directly to your smartphone.
              </p>

              <h4>Motion Detection</h4>
              <p>
                Smart cameras come with motion sensors that alert you
                immediately if any unusual activity is detected, offering an
                extra layer of security.
              </p>

              <h4>Two-Way Communication</h4>
              <p>
                Some models feature built-in speakers and microphones, allowing
                you to communicate directly with family members or even
                potential intruders through the camera.
              </p>

              <h4>Cloud and Local Storage</h4>
              <p>
                Choose between storing your videos in the cloud for easy access
                or on local storage devices for added privacy and control.
              </p>

              <h4>Integration with Smart Home Systems</h4>
              <p>
                Easily integrate your smart camera with other smart home devices
                and control them through a single interface on your smartphone
                or through voice commands.
              </p>
            </section>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DBList
            noAuth
            filter={filterFunction}
            path="products"
            Component={Product}
          />
        </Col>
      </Row>
    </Container>
  );
};
