import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Heading, useEloise } from "typed-component-gallery";
import ReusableBlogPost from "../products/blogPost";
import { DBList } from "../widgets/dbList";

const Product: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  path: string;
}> = ({ data, update, path }) => {
  const { logic } = useEloise();

  return (
    <Row>
      <ReusableBlogPost
        {...data}
        preview
      />
    </Row>
  );
};
export const Daily: React.FC = () => {
  const filterFunction = (data: any) => {
    console.log(data.data.section);
    return data.data.active && data.data.section === "daily";
  };
  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Heading size={3}>Deals of the Day</Heading>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DBList
            noAuth
            filter={filterFunction}
            path="products"
            Component={Product}
          />
        </Col>
      </Row>
    </Container>
  );
};
