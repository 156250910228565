import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useEloise } from "typed-component-gallery";
import otherLight from "../assets/otherLight.jpg";
import ringLight from "../assets/ringLight.jpg";
import ReusableBlogPost from "../products/blogPost";
import { DBList } from "../widgets/dbList";
const Product: React.FC<{
  data: any;
  update: (data: any) => Promise<any>;
  path: string;
}> = ({ data, update, path }) => {
  const { logic } = useEloise();

  return (
    <Row>
      <ReusableBlogPost
        {...data}
        preview
      />
    </Row>
  );
};
export const Lighting: React.FC = () => {
  const filterFunction = (data: any) => {
    return data.data.active && data.data.cat === "lighting";
  };
  return (
    <Container>
      <Row className="my-4">
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/3sXBCWN"
            target="_blank">
            <img
              style={{ maxWidth: "25vw", textAlign: "center" }}
              src={ringLight}
            />
          </a>
        </Col>
        <Col
          lg={4}
          className="text-center mx-auto">
          <a
            href="https://amzn.to/3ra73Na"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={otherLight}
            />
          </a>
        </Col>
        {/* <Col
          lg={4}
          className="text-center">
          <a
            href="https://amzn.to/455Vjtf"
            target="_blank">
            <img
              style={{ maxWidth: "29vw", textAlign: "center" }}
              src={homePod}
            />
          </a>
        </Col> */}
      </Row>
      <Row className="my-3">
        <Col
          lg={11}
          style={{ borderRadius: "5px", border: "1px solid black" }}
          className="mx-auto ">
          <div className=" p-3">
            {" "}
            <section>
              <h3>Unlock the Potential of Smart Lighting</h3>
              <p>
                💡 Discover how smart lighting can elevate your home experience.
                It's not just about turning lights on and off anymore!
              </p>

              <h4>Mood Setting</h4>
              <p>
                Transform your home environment with lighting that sets the
                mood. Whether it's a romantic dinner, a relaxing evening, or a
                party, smart lights can help you create the perfect atmosphere.
              </p>

              <h4>Energy Efficiency</h4>
              <p>
                Smart lighting solutions are optimized for energy-saving.
                Schedule your lights to turn off when you're not home, or sync
                them with the sunrise and sunset to reduce energy consumption.
              </p>

              <h4>Security</h4>
              <p>
                Enhance your home's security by setting your lights to deter
                potential intruders. Simulate presence, or set up motion sensors
                to turn lights on and off automatically.
              </p>

              <h4>Health and Well-Being</h4>
              <p>
                With tunable whites and sleep/wake schedules, smart lighting can
                also contribute to a healthy sleep routine by mimicking the
                natural progression of daylight.
              </p>

              <h4>Voice and App Control</h4>
              <p>
                No need to fumble for a switch when you can control your
                lighting with your voice or smartphone. Many smart lighting
                systems are compatible with virtual assistants for hands-free
                control.
              </p>
            </section>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <DBList
            noAuth
            filter={filterFunction}
            path="products"
            Component={Product}
          />
        </Col>
      </Row>
    </Container>
  );
};
