import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router";
import {
  Button,
  Checkbox,
  DropDown,
  EloisePage,
  Heading,
  Input,
  useEloise,
} from "typed-component-gallery";
import amazon1 from "../assets/amazon1.jpg";
import ReusableBlogPost from "../products/blogPost";
import { DBList } from "../widgets/dbList";

import FileTable from "../widgets/fileTable";

import { v4 as uuidv4 } from "uuid";
import { cats, sections } from "../../config/pages";

export const Admin = () => {
  const { logic } = useEloise();
  const [user] = useAuthState(logic.fb.auth);
  const [allowed, setAllowed] = useState(false);

  const [link, setLink] = useState<string>();
  const [previewText, setPreviewText] = useState<string>();
  const [bodyText, setBodyText] = useState<Array<string>>();
  const [title, setTitle] = useState<string>();
  const [image, setImage] = useState<any>();
  const [active, setActive] = useState<boolean>(true);
  const [section, setSection] = useState<string>("daily");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [cat, setCat] = useState<string>();
  const pathName = useLocation().pathname;

  const navigate = useNavigate();

  const submitProduct = async () => {
    if (link && previewText && bodyText && image && title) {
      let ots = { link, previewText, bodyText, image, title, section, active };
      let id = uuidv4();

      let x: any = await logic.fb.docs.setDoc("products/" + id, ots);
      console.log(x);

      alert("Product Added");
    } else {
      alert("Somethings not right dude");
    }
  };
  const [inputText, setInputText] = useState<string>("");

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(event.target.value);
  };

  const convertToParagraphs = () => {
    const paragraphs = inputText.split("\n").filter((p) => p.trim() !== "");
    setBodyText(paragraphs);
  };

  const Product: React.FC<{
    data: any;
    update: (data: any) => Promise<any>;
    path: string;
  }> = ({ data, update, path }) => {
    const [active, setActive] = logic.fb.hooks.useThrottleFieldNoAuth<boolean>(
      path,
      "active",
      150
    );
    const [section, setSection] = logic.fb.hooks.useThrottleFieldNoAuth<string>(
      path,
      "section",
      150
    );
    const [cat, setCat] = logic.fb.hooks.useThrottleFieldNoAuth<string>(
      path,
      "cat",
      150
    );

    return (
      <Col
        lg={3}
        className="mx-auto">
        <Row
          className="my-5"
          style={{ borderRadius: "5px", border: "1px solid black" }}>
          <ReusableBlogPost
            {...data}
            preview
          />
          <Row className="my-4">
            <Col
              lg={5}
              className="mx-auto">
              <Checkbox
                label="Active"
                state={active}
                setState={setActive}
              />
            </Col>
            <Col
              lg={5}
              className="mx-auto">
              <DropDown
                options={[
                  ...sections.map((x: EloisePage) => x.url as string),
                  "cat",
                ]}
                state={section}
                setState={setSection}
                label="section"
              />

              <DropDown
                options={[...cats.map((x: EloisePage) => x.url as string)]}
                state={cat}
                setState={setCat}
                label="section"
              />
            </Col>
          </Row>
        </Row>
      </Col>
    );
  };

  logic.hooks.useAsyncEffect(async () => {
    if (user) {
      let test = await logic.fb.docs.getUser();
      if (!test.perms || test.perms !== "admin") {
        navigate("/");
      } else {
        setAllowed(true);
      }
    } else {
      navigate(pathName, { state: { sideWidget: "Login" } });
    }
  }, [user]);

  useEffect(() => {
    convertToParagraphs();
  }, [inputText]);

  const handleUpload = (x: any) => {
    setImage(x);
    setImageUploaded(true);
  };

  return allowed ? (
    <>
      <Row className="mb-5">
        <Heading size={4}>Welcome Admin</Heading>
      </Row>
      <Row>
        <Col
          lg={5}
          className="mx-auto">
          <div style={{ border: "1px solid black", borderRadius: "5px" }}>
            <Row>
              <Heading size={3}>New Product</Heading>
            </Row>
            <Row className="my-3">
              <Col
                lg={5}
                className="mx-auto">
                <Input
                  state={title}
                  setState={setTitle}
                  label="title"
                  extLabel
                />
              </Col>
              <Col
                lg={5}
                className="mx-auto">
                <Input
                  state={previewText}
                  setState={setPreviewText}
                  label="Preview Text"
                  extLabel
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col
                lg={5}
                className="mx-auto">
                <Row className="my-3">
                  <Input
                    state={link}
                    setState={setLink}
                    label="Link"
                    extLabel
                  />
                </Row>
                <Row className="my-3">
                  <textarea
                    style={{ width: "100%", height: "300px" }}
                    placeholder="Enter body text here..."
                    onChange={handleTextChange}
                    value={inputText}></textarea>
                </Row>
              </Col>
              <Col
                lg={5}
                className="mx-auto">
                {image ? (
                  <img
                    src={image}
                    style={{ maxWidth: "200px" }}
                  />
                ) : (
                  <FileTable
                    actions={[]}
                    folderPath="siteData/futureLiving/products/"
                    noAuth
                    uploadCallBack={handleUpload}
                  />
                )}
              </Col>
            </Row>
            <Row className="my-3">
              <Col
                lg={5}
                className="mx-auto">
                <Checkbox
                  label="Active"
                  state={active}
                  setState={setActive}
                />
              </Col>
              <Col
                lg={5}
                className="mx-auto">
                <DropDown
                  options={[...cats.map((x: EloisePage) => x.url as string)]}
                  state={cat}
                  setState={setCat}
                  label="Category"
                />
              </Col>
            </Row>
            <Row className="my-3">
              <Col
                lg={5}
                className="mx-auto"
              />
              <Col
                lg={5}
                className="mx-auto">
                <DropDown
                  options={sections.map((x: EloisePage) => x.url as string)}
                  state={section}
                  setState={setSection}
                  label="section"
                />
              </Col>
            </Row>
            {link && previewText && bodyText && image && title && (
              <Row className="my-3">
                <Col
                  lg={4}
                  className="mx-auto">
                  <Button onClick={submitProduct}>Submit</Button>
                </Col>
              </Row>
            )}
          </div>
        </Col>
        <Col
          lg={5}
          className="mx-auto"
          style={{ border: "1px solid black", borderRadius: "5px" }}>
          <Heading size={3}>Preview</Heading>
          <ReusableBlogPost
            title={title ?? "Title"}
            previewText={previewText ?? "Preview Text"}
            link={link ?? "Link"}
            bodyText={bodyText ?? ["Body Text"]}
            image={image ?? amazon1}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Heading size={3}>Gallery</Heading>
        <DBList
          noAuth
          path="products"
          Component={Product}
        />
      </Row>
    </>
  ) : (
    <></>
  );
};
